.congratulations-heading,
.top-navigation h2,
.top-navigation p,
h1,
label,
p {
    line-height: normal
}

body,
html {
    overflow-x: hidden
}

.custom-input {
    height: 60px;
    font-size: 18px !important;
}

.top-navigation h2,
h1 {
    color: #000;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;

}

.cst-ptr,
.verify {
    cursor: pointer
}

/* ul ul a,
ul.CTAs a {
    font-size: .9em !important
} */

body {
    font-family: 'Work Sans', sans-serif;
    margin: 0;
    padding: 0
}

h1 {
    letter-spacing: -1.12px
}

.top-navigation h2 {
    letter-spacing: -.64px
}

.top-navigation p,
p {
    font-weight: 500;
    font-style: normal
}

.top-navigation p {
    color: #bcbcbc;
    /* font-size: 19px; */
    font-size: 24px;
    letter-spacing: -.76px
}

.cst-primary-color,
.email-color-span {
    color: #492a99
}

.cst-secondary-color {
    color: #bcbcbc !important;
}

p {
    color: #bcbcbc;
}

.black-darkish-color {
    color: rgb(79, 79, 79);
    font-weight: 600
}

.btn {
    font-size: 22px
}

/* .css-1ud1chn-control {
    flex-wrap: unset !important;
    height: 60px;
}

.css-1up50s6-control {
    flex-wrap: unset !important;
    height: 60px;
}

.css-1q7xutl-control {
    flex-wrap: unset !important;
    height: 60px;
}

.css-1reto9o-control {
    min-height: 60px;
    max-height: 60px;
    flex-wrap: unset !important;
}

.css-1q7xutl-control:hover {
    flex-wrap: unset !important;
    height: 60px;
}

.nsm7Bb-HzV7m-LgbsSe {
    border: none !important;
    font-size: 22px !important;
    background-color: #fff !important
} */

.already-have-account {
    margin-top: 30px
}

p {
    font-size: 24px;
    letter-spacing: -.96px;
    margin: 5px 0
}

label,
th {
    /* font-size: 18px; */
    letter-spacing: -.72px
}

.welcome-heading {
    margin-top: 53px;
    /* font-size: 56px */
    font-size: 32px
}

label {
    color: #1d1c1b;
    font-style: normal;
    font-weight: 600
}

.hero-left-col {
    background-image: url("Assets/Images/heroImage.png");
    background-size: cover;
    padding-top: 80px;
    position: sticky;
    height: 100vh;
    min-height: 100%;
    background-position: center
}

.input-group-lg svg,
.verify,
a[data-toggle=collapse] {
    position: relative
}

.congratulations-heading {
    color: #fff;
    font-family: Pacifico;
    /* font-size: 80px; */
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 2.4px
}

.congratulations-owow,
.owow,
.owow-lets-started {
    font-family: Pacifico, cursive
}

.left-clumn-first-heading,
.left-clumn-heading,
.verified-button,
.verify {
    font-weight: 700;
    line-height: normal;
    font-style: normal
}

.hero-left-col::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, #000 100%)
}

.left-clumn-first-heading {
    font-size: 102px;
    margin-top: 22%
}

.left-clumn-heading {
    font-size: 60px
}

.input-group-lg svg {
    cursor: pointer;
    left: 94%;
    top: -42px;
    font-size: 22px
}


td {
    /* font-size: 20px */
    font-family: Work Sans;
}

th {
    font-family: Work Sans;
}

.verify {
    left: 86%;
    top: -42px;
    color: var(--Secondary-Colour---Dark-Purple, #492a99);
    text-align: right;
    letter-spacing: -.8px;
    text-decoration-line: underline
}

.verified-button {
    color: #fff !important;
    border-radius: 37px;
    border: 2px solid #fff !important;
    padding: 19px 35px;
    font-size: 30px
}

.btn-time-pm,
.cst-button-alignment,
.time-input {
    display: flex;
    justify-content: center;
    gap: 10px
}

.owow {
    color: #000;
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: 63px
}

.add-kit-button,
.social-media-btn,
.uplod-copmany-logo {
    font-style: normal;
    font-weight: 500;
    letter-spacing: -.88px
}

.create-account-btn {
    border-radius: 16px !important;
    background: #492a99 !important;
    color: #fff !important;
    padding: 16px
}

.add-kit-button {
    border-radius: 16px;
    border: 1px solid #a48ed8 !important;
    background: #dcceff !important;
    align-items: center;
    min-width: 158px;
    min-height: 58px;
    color: #492a99 !important;
    line-height: normal
}

.uplod-copmany-logo {
    border-radius: 16px;
    border: 1px solid #a48ed8 !important;
    background: #fff !important;
    align-items: center;
    min-width: 158px;
    min-height: 58px;
    color: #492a99 !important;
    line-height: normal
}

.create-account-btn-outline {
    border-radius: 16px;
    color: #492a99 !important;
    border: 1px solid #492a99 !important
}

.dashboard-btn {
    border-radius: 16px;
    border: 1px solid #492a99;
    background: #fff !important;
    color: #492a99 !important
}

.divide-span {
    width: 50%;
    height: 1px;
    color: #000;
    border: 1px solid;
    opacity: .1
}

.log-in,
.social-media-btn {
    color: #492a99 !important;
    line-height: normal
}

.social-media-btn {
    font-size: 22px;
    border-radius: 16px;
    border: 1px solid #492a99 !important;
    background: #fff !important;
    /* padding: 16px */
}

.heado-para,
.log-in {
    letter-spacing: -.96px;
    font-style: normal
}

.lets-get-started-container,
.welcome-lets-started-container {
    background: linear-gradient(180deg, #492a99 0, #000 100%);
    height: 100vh
}

.log-in {
    font-size: 24px;
    font-weight: 700;
    text-decoration-line: underline !important
}

.already-account {
    color: #1d1c1b
}

.right-side-container {
    margin-left: 90px
}

.right-side-container-personal {
    margin: 56px 56px 0
}

.fix-margin-for-or,
.margin-fix-bottam {
    margin-bottom: 30px
}

.marshmello-image-box {
    margin-top: 26%
}

.heado-para {
    margin-top: 8px;
    color: #bcbcbc;
    font-weight: 500;
    line-height: 34px;
    font-size: 22px
}

.right-side-container {
    margin-right: 60px
}


#flexCheckDefault:checked,
input[type=radio]:checked {
    background-color: #492a99;
    border: 1px solid #492a99;
    /* font-size: 20px;
    border-radius: 3px !important; */
}

input[type=checkbox]:checked {
    background-color: #492a99;
    border: 1px solid #492a99;
    font-size: 20px;
    border-radius: 3px !important;
}

input[type=checkbox] {
    font-size: 20px;
    border-radius: 3px !important;
    border: 2px solid #d9d9d9
}

textarea {
    border-radius: 12px !important;
    border: 1px solid #492a99 !important;
    background: #fff;
    font-weight: 500 !important;
    font-size: 18px !important;
}

input[type=date],
input[type=email],
input[type=password],
input[type=tel],
input[type=text],
input[type=number] {
    border-radius: 12px !important;
    border: 1px solid #492a99;
    background: #fff;
    /* height: 60px; */
    font-weight: 500;
    font-size: 18px !important;
}

.css-qbdosj-Input {
    margin-top: -12px !important
}

input[type=radio] {
    width: 1.15em;
    height: 1.15em;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 2px solid #000;
    border-radius: 50%;
    outline: 0
}

::placeholder {
    color: #bcbcbc !important;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -.8px
}

::-ms-input-placeholder {
    color: #bcbcbc !important;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -.8px
}

.owow {
    margin-top: 50px
}

.fix-margin-for-or {
    margin-top: 40px
}

.owow-verification-email {
    margin-bottom: 150px
}

.mail-box {
    margin-bottom: 40px
}

.welcome-lets-started-container {
    min-height: 100%
}

.owow-lets-started {
    padding-top: 58px;
    /* color: #fff;
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: 63px */
}

.welcome-lete-started {
    margin-top: 200px;
    color: #fff;
    font-weight: 700
}

.personal-details-heading {
    color: #fff;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal
}

.personal-details-heading-right-side {
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -.8px;
    margin-top: 92px
}

.message-inputs input {
    width: 60px;
    height: 60px
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0
}

.congratulations-owow {
    margin-bottom: 120px;
    color: #000;
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
    line-height: 63px
}

.rejected-button,
th {
    line-height: normal;
    font-style: normal;
    font-weight: 600
}

.mobile-two-buttons {
    gap: 10px
}

.owow-savings {
    width: 50%
}

th {
    color: #bcbcbc !important;
    text-transform: uppercase;
}

th:not(:last-child) {
    min-width: 160px;
}

.button-lag-proficiancy {
    background-color: #fff !important;
    color: black !important;
    font-size: 16px !important;
    font-weight: 500;
    border-radius: 12px;
    border: 1px solid black;
    border-color: black !important;
}

button.btn.button-lag-proficiancy.button-lag-proficiancy-active {
    background-color: #492a99 !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 500;
    border-radius: 12px;
    border: 1px solid black;
    border-color: black !important;
}

.delete-button {
    background-color: #fff;
    border-radius: 16px !important;
    color: red !important;
    border: 1px solid red !important;
    font-size: 18px;
    min-height: 58px;
    min-width: 158px;
}

.hor-line-primary,
.hor-line-secondary {
    border-radius: 41px;
    width: 102px;
    height: 6px
}

.hor-line-primary {
    background: #492a99
}

.hor-line-secondary {
    background: #eae2ff
}

.progress-lines {
    gap: 10px;
    flex-direction: row
}

/* .mobile-logo {
    display: none
} */

.hire-talents-card {
    min-width: 374px;
    min-height: 163px;
    border-radius: 23px;
    border: 1px solid #dadada;
    background: #fff;
}

.hire-talents-card h5 {
    color: #BCBCBC;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.56px;
}

.hire-talents-card h6 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.56px;
    text-transform: capitalize;
}

.hire-talents-card p {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.72px;
    text-transform: capitalize;

}

.job-posted-card-cst {
    min-width: 480px;
    min-height: 163px;
    border-radius: 23px;
    border: 1px solid #dadada;
    background: #fff;
}

.job-posted-card-cst h5 {
    color: #BCBCBC;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.56px;
    text-transform: capitalize;

}

.job-posted-card-cst h6 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.56px;
    text-transform: capitalize;

}

.job-posted-card-cst p {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.72px;
    text-transform: capitalize;

}

.cst-btn-tags {
    border-radius: 24px;
    border: 1px solid #492a99 !important;
    background: #fff !important;
    font-size: 12px !important;
    pointer-events: none;
}

.rejected-button {
    border-radius: 18px;
    background: #ffdee2 !important;
    min-width: 302.203px;
    padding: 7px 10px;
    color: #eb001b !important;
    letter-spacing: -.56px;
    border: none;
    font-size: 14px
}

.developer-information-card {
    border-radius: 18px;
    border: 1px solid #dadada;
    background: #fff;
    max-width: 346px;
    min-height: 467px;
    flex-shrink: 0;
    font-size: 14px;
    height: 100%;
}

.developer-information-card h5 {
    color: black;
    font-weight: 700;
    word-wrap: break-word;
    margin: 0;
}

.developer-information-card label {
    color: #BCBCBC;
    /* font-size: 16px; */
    font-weight: 500;
    word-wrap: break-word
}

.interview-schdule-button {
    border-radius: 18px;
    background: #ffeed6 !important;
    min-width: 302.203px;
    padding: 7px 10px;
    color: #f79e1b !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -.56px;
    font-size: 14px
}

.btn-assessment,
.btn-recommended,
.btn-schdule,
.btn-tag-final-interview,
.btn-tag-senior,
.btn-time-am,
.btn-time-pm,
.button-container,
.hire-now-btn,
.view-profile-btn {
    font-weight: 500;
    font-style: normal
}

.btn-recommended,
.hire-now-btn {
    color: #fff !important;
    line-height: normal
}

.view-profile-btn {
    min-width: 113.873px;
    min-height: 39px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #1d1c1b !important;
    background: #fff;
    font-size: 14px !important;
}

.hire-now-btn {
    border-radius: 8px;
    background: #492a99 !important;
    min-width: 177.38px;
    min-height: 39px;
    font-size: 14px !important;
    letter-spacing: -.56px
}

.card-model-healthcare {
    /* padding: 16px 19px; */
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    border: 1px solid #ece4ff;
    background: #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25)
}

.eqiupment-checkbox {
    font-size: 22px !important
}

.btn-recommended {
    padding: 1.119px 6.298px 1.03px 5.598px;
    border-radius: 123.134px;
    background: #492a99 !important;
    font-size: 15.672px !important;
    letter-spacing: -.627px
}

.btn-assessment {
    display: inline-flex;
    /* padding: 8px 12px; */
    align-items: center;
    gap: 4px;
    border-radius: 74px;
    border: 1px solid #492a99 !important;
    background: #fff;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Work Sans;
    font-size: 12px;
    line-height: normal;
    letter-spacing: -.48px;
    font-size: 12px !important;
}

.btn-for-request-interview {
    /* min-height: 70px; */
    border-radius: 16px;
    color: #fff !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -.88px
}


.btn-for-reject {
    padding: 16px 22px;
    background: red !important;
    border-radius: 16px;
    color: #fff !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -.88px;
    width: 16%;
}


.btn-for-request-interview {
    padding: 16px 48px;
    flex-shrink: 0;
    background: #492a99 !important
}

.btn-tag-final-interview,
.btn-tag-senior {
    text-align: right;
    font-size: 16px !important;
    line-height: normal;
    letter-spacing: -.64px;

}

.btn-tag-senior {
    border-radius: 8px;
    border: 1px solid #f79e1b !important;
    background: #ffeacb !important;
    color: #1d1c1b;
}

.btn-tag-final-interview {
    border-radius: 8px !important;
    border: 1px solid #492a99 !important;
    background: #eae2ff !important;
    color: #492a99 !important
}

.btn-time-am,
.btn-time-pm {
    min-width: 79px;
    font-size: 18px;
    line-height: normal;
    letter-spacing: -.72px;
    /* min-height: 58px */
}

.btn-close-cst {
    border: none;
    background-color: #fff
}

.time-input {
    width: 79px;
    height: 59px;
    align-items: center;
    flex-shrink: 0;
    padding-left: 28px
}

.btn-time-am {
    border-radius: 10px;
    border: 1px solid #492a99 !important;
    background: #fff !important;
    color: #492a99
}

.btn-time-pm {
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    background: #492a99;
    color: #fff;
    font-family: Work Sans
}

.btn-schdule,
.button-container {
    line-height: normal;
    letter-spacing: -.88px;
    display: flex
}

.first-dot-img {
    margin-top: 24px
}

.second-dot-img {
    margin-top: 58px
}

.third-dot-img {
    margin-top: 64px
}

.btn-schdule {
    width: 192px;
    height: 70px;
    padding: 17px 48px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: #492a99 !important;
    color: #fff !important;
}

.cst-profile-info-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row
}

.cst-save-das {
    min-width: 159px
}

.cst-account-card {
    padding: 30px
}

.button-active {
    border-radius: 18px;
    background: #30b55c;
    padding: 6px 9px;
    color: #fff;
    font-size: 14px;
    border: none
}

.button-active.active-green {
    border-radius: 18px;
    background: #30b55c;
    padding: 8px 20px;
    color: #fff;
    font-size: 16px;
    border: none
}

.button-active.inactive-red {
    border-radius: 18px;
    background: red;
    padding: 8px 20px;
    color: #fff;
    font-size: 16px;
    border: none
}

.button-container {
    align-items: center;
    /* color: #1d1c1b !important; */
    /* font-size: 22px; */
    background: #fff !important;
    padding: 16px;
    /* padding: 20px; */
    /* gap: 30px; */
    gap: 6px;
    border-radius: 16px;
    border: 1px solid #492a99;
}

.label-container {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    text-align: initial
}

.change-password {
    color: #492a99;
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -.96px
}

.btn-savings {
    font-size: 20px;
    font-style: normal;
    line-height: normal
}

.change-your-login {
    font-size: 18px;
    font-style: normal;
    line-height: normal
}

.change-your-login {
    color: #bcbcbc;
    font-weight: 500;
    letter-spacing: -.8px
}

.overview-sec-label {
    color: #bcbcbc;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -.64px
}

a,
a:focus,
a:hover {
    color: inherit;
    text-decoration: none;
    transition: .3s
}

.navbar {
    padding: 15px 10px;
    background: #490d0d;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .1)
}

.navbar-btn {
    box-shadow: none;
    outline: 0 !important;
    border: none
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0
}

.wrapper {
    width: 100%;
    background-color: #fbf9ff
}

/* #sidebar {
    min-width: 300px;
    max-width: 250px;
    color: #000;
    transition: .3s;
    border-right: 1px solid #bcbcbc;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1
} */

/* #sidebar {
    min-width: 300px;
    max-width: 250px;
    color: #000;
    transition: .3s;
    border-right: 1px solid #bcbcbc;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    overflow-y: scroll;
} */

#sidebar {
    width: 300px;
    color: #000;
    transition: .3s;
    border-right: 1px solid #bcbcbc;
    position: fixed;
    height: 100vh;
    /* Set a fixed height for the sidebar */
    top: 0;
    left: 0;
    z-index: 1;
    overflow-y: auto;
    /* Use auto instead of scroll */
}

#sidebar::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar width */
}

#sidebar::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Make scrollbar thumb transparent */
}

/* remove scollbar for tables  */


#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: .3s;
    margin-left: 300px;
    max-height: 100vh;
    overflow-y: auto;
}


/* #content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: .3s;
    margin-left: 300px
} */


#sidebar.active {
    margin-left: -250px;
}

#sidebar .sidebar-header {
    padding: 24px 40px
}

#sidebar ul.components {
    /* padding: 20px 0; */
    margin: 6px
}

#sidebar ul p {
    color: #fff;
    padding: 10px
}

#sidebar ul li a {
    padding: 12px;
    font-size: 1.1em;
    display: block;
    gap: 10px;
    font-weight: 500;
}

#sidebar ul li a:hover {
    background: #cebaff;
    border-radius: 16px
}

/* #sidebar ul li.active>a,
a[aria-expanded=true] {
    color: #000;
    border-radius: 17px;
    background: #cebaff
} */

#sidebar ul li.active>a {
    color: #000;
    border-radius: 17px;
    background: #cebaff
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%)
}

ul ul a {
    padding-left: 30px !important
}

ul.CTAs a {
    text-align: center;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px
}

a.download {
    background: #fff;
    color: #7386d5
}

a.article,
a.article:hover {
    background: #cebaff !important;
    color: #fff !important;
    border-radius: 17px
}

.cost-c-td,
.cost-c-th {
    border: 2px solid #000;
    text-align: center;
    border-left: none;
    border-top: none;
}

.my-account-tabs-list {
    position: relative;
    list-style: none;
    padding: 0;
    display: flex;
    gap: 24px;
    /* flex-wrap: wrap; */
}

ul li a.active:after {
    content: '';
    width: 100%;
    left: 0;
    height: 1px;
    background: #bcbcbc;
    position: absolute;
    top: 36px
}

.my-account-tabs-list a {
    color: #bcbcbc;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -.96px
}

.my-account-tabs-list a.active {
    border-bottom: 2px solid #492a99;
    height: 36px;
    display: block;
    color: #492a99;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -.96px
}

.btn-home-page-not-found {
    color: #fff !important;
    font-weight: 700;
    word-wrap: break-word;
    background-color: #492a99 !important;
    border-radius: 50px
}

.btn-indirect-cost,
.btn-salary {
    font-style: normal;
    font-weight: 500;
    line-height: normal
}

.cost-c-th {
    padding: 16px !important
}

.cost-c-th h4 {
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -.96px
}

.cost-c-td h2 {
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -1.28px
}

.cost-c-td {
    padding: 38px !important
}

.btn-salary {
    color: #000 !important;
    border-radius: 12px !important;
    background: #ab8bfc !important;
    border: none
}

.btn-indirect-cost {
    border-radius: 12px !important;
    background: #492a99 !important;
    color: #fff !important;
    border: none
}

.table-header-button {
    display: flex;
    gap: 24px;
    justify-content: center
}

.btn-doller-first-td {
    border-radius: 22px;
    background: #ab8bfc !important;
    width: 440.422px;
    flex-shrink: 0;
    border: none
}

.btn-btn-doller-second-td {
    border-radius: 22px;
    background: #492a99 !important;
    width: 301px;
    flex-shrink: 0;
    color: #fff !important
}

.btn-savings {
    border-radius: 12px;
    background: #a7f8c2 !important;
    color: #000;
    font-weight: 700;
    letter-spacing: -.8px
}

.cst-card {
    border-radius: 36px;
    border: 1px solid #000
}

.cst-border-table {
    border-radius: 36px;
    background: #ab8bfc;
    border: 1px solid;
}

#file,
.btn-view-profile,
.cstkanda {
    background: #fff !important
}

.cst-bg-blue-tooltip {
    background: rgba(73, 42, 153, 1) !important
}

.cst-bg-blue-tooltip {
    padding: 10px !important;
    color: #fff !important;
    border: none;
    max-width: 357px;
    border-radius: 18px !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .25) !important;
    text-align: left;
    z-index: 9999
}

.cst-border-btn-clock {
    border: 1px solid #492A99;
    border-radius: 16px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
}

.prabobationdaysinfo {
    background: #000 !important
}

.save-engineering-cst {
    background: #000 !important;
    color: #fff !important;
    padding: 10px !important;
    border: none;
    max-width: 357px;
    border-radius: 12px !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .25) !important;
    text-align: left;
    z-index: 9999
}

.no-bottom-border {
    border-bottom: none
}

.span-savings {
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.28px
}

.btn-add-talent,
.product-designer-subheading {
    font-style: normal;
    font-weight: 500;
    line-height: normal
}

.navtab_wrap .nav-tabs {
    background: #492a99;
    border: none;
    border-radius: 100px
}

.navtab_wrap .nav-tabs .nav-link.active {
    background: #ab8bfc;
    border-radius: 100px;
    color: #000
}

.navtab_wrap .nav-tabs .nav-link {
    align-items: center;
    border: none;
    color: #fff;
    display: flex;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    padding: 0;
    transition: .5s ease-in-out;
    font-size: 24px
}

.cstkanda {
    padding: 10px !important;
    color: #000 !important;
    border: none;
    max-width: 357px;
    border-radius: 12px !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .25) !important;
    text-align: left;
    z-index: 9999
}

.prabobationdaysinfo {
    padding: 10px !important;
    color: #fff !important;
    border: none;
    max-width: 357px;
    border-radius: 12px !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .25) !important;
    text-align: left;
    z-index: 9999
}

.btn-add-talent,
.btn-view-profile {
    padding: 8px 20px;
}

#file {
    border-radius: 12px !important;
    border: 1px solid #492a99 !important
}

.product-designer-subheading {
    color: #bcbcbc;
    font-size: 16px;
    letter-spacing: -.64px
}

.btn-view-profile {
    min-width: 104px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #1d1c1b !important
}

.btn-add-talent {
    color: #fff !important;
    letter-spacing: -.56px;
    min-width: 162px;
    border-radius: 8px;
    background: #bcbcbc !important
}

.create-account-btn-outline-ractangle {
    border-radius: 8px;
    color: #492a99 !important;
    border: 1px solid #492a99 !important;
    min-width: 162px;
    padding: 12px;
}

.create-account-btn-ractangle {
    border-radius: 8px;
    background: #492a99 !important;
    color: #fff !important;
    min-width: 162px
}

.add-talent-card {
    border-radius: 18px;
    border: 1px solid #dadada;
    background: #fff
}

.details-card-for-input {
    flex-shrink: 0;
    border-radius: 26px;
    background: #fff
}

.details-card-for-input a.dropdown-item {
    background-color: #fff !important;
    color: #000 !important
}

.details-card-for-input ul li:first-child {
    padding-top: 4px
}

.details-card-for-input ul li:last-child {
    padding-bottom: 4px
}

.details-card-for-input .dropdown.add-dropdwon-cst {
    border-radius: 12px !important;
    border: 1px solid #000 !important;
    background: #fff !important;
    max-width: 112px
}

.details-card-for-input .dropdown.add-dropdwon-cst .btn-add-dropdwon-cst {
    font-size: 20px;
    color: #492a99 !important;
    border: none
}

.details-card-for-input .dropdown-menu.show {
    border: 1px solid #000
}

.regsiter-page-lists {
    list-style-type: none
}

.card-small-cst {
    max-width: 570px;
    min-height: 72px;
    border-radius: 20px;
    background: #fafafa;
    border: none
}

.employee-my-profile h2 {
    font-weight: 700;
    word-wrap: break-word
}

.add-skill-right-button {
    color: #492A99 !important;
    font-size: 16px !important;
    font-weight: 500;
    word-wrap: break-word;
    border-radius: 12px;
    border: 1px solid #492A99 !important;
}

.add-filter-right-button {
    color: #000 !important;
    font-size: 18px !important;
    font-weight: 500;
    word-wrap: break-word;
    border-radius: 12px;
    border: 1px solid #000 !important;
    height: 50px;
}

.employee-white-card {
    background-color: white;
    border-radius: 12px;
}

.btn-blue-btn-tag {
    background-color: #AB8BFC !important;
    color: black !important;
    font-size: 16px !important;
    font-weight: 500;
    border-radius: 12px;
}

.formobile-all-jobs {
    align-items: center;
}

.view-details-btn {
    background-color: #492A99 !important;
    color: white !important;
    font-size: 22px;
    font-weight: 500;
    word-wrap: break-word;
    /* border-radius: 16px; */
}

.under-review-btn {
    border: 1px #492A99 solid !important;
    background-color: #fff !important;
    color: #492A99 !important;
    font-size: 22px;
    font-weight: 500;
    word-wrap: break-word
}

.billing-invoice-details tbody {
    border-color: inherit;
    border-style: hidden;
    border-width: 0;
}

.billing-invoice-details thead {
    border-color: inherit;
    border-style: hidden;
    border-width: 0;
}

.billing-invoice-details tfoot {
    border-color: inherit;
    border-style: hidden;
    border-width: 0;
}

.billing-invoice-details td {
    border-color: inherit;
    border-style: hidden;
    border-width: 0;
}

.billing-invoice-details th {
    background-color: #FAFAFA;
    border-color: inherit;
    border-style: hidden;
    border-width: 0;
}

.billing-invoice-details tr {
    background-color: #FAFAFA;
    border-color: inherit;
    border-style: hidden;
    border-width: 0;
}

.card-payment-blue {
    border-radius: 38px;
    background: #FBF9FF;
}

.card-payment-blue h2 {
    font-weight: 600;
}

.card-payment-blue h6 {
    font-weight: 600;
}

.pay-now-btn {
    border-radius: 16px !important;
    border: 1px solid #492A99 !important;
    background: #FFF;
    color: #492A99 !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.88px;
}

.manully-pay-btn {
    border-radius: 16px !important;
    border: 1px solid #492A99 !important;
    background: #492A99 !important;
    color: #fff !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.88px;
}

.button-pending-payment {
    border-radius: 18px;
    background: #F79E1B !important;
    color: white !important;
    border-radius: 18px;
    padding: 8px 20px;
    font-size: 16px;
    border: none;

}

.checkbox-text {
    font-size: 18px;
    letter-spacing: -.96px;
    margin: 5px 0;
}

.password-validation-card {
    /* display: flex;   */
    border-radius: 21px;
    background: #F4EFFF;
}

.password-validation-card input[type=checkbox] {
    font-size: 20px;
    border-radius: 12px !important;
    border: 2px solid #d9d9d9;
}

.password-validation-card ul {
    padding-left: 0.5rem
}

.password-validation-card div {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.password-validation-card li {
    list-style-type: none
}

.cst-account-card {
    border-radius: 36px;
    background: #FFF;
    border: none;
    height: 100%;
}

.phno {
    border-radius: 12px;
    border: 1px solid #492a99;
    background: #fff;
    font-size: 18px;
    height: 60px;
    font-weight: 500;
    width: 106px;
}



.m-cst-mobile-int .dropdown-item {
    max-width: 370px;
    min-height: 42px;
    border-radius: 20px;
    background: #ffffff;
    border: none;
}

.m-cst-mobile-int .dropdown-menu.show {
    border: 1px solid #492a99;
    border-radius: 12px;
}

.input-group-lg .react-datepicker-wrapper {
    width: 100%;
}

/* .css-1u9des2-indicatorSeparator {
    display: none;
} */

/* DatePicker.css */

.react-datepicker__day--selected {
    background-color: #492a99 !important;
    color: #fff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--in-month {
    background-color: #eae2ff !important;
    color: inherit !important;
}

.dahbord-cst-card {
    border-radius: 16px;
    background: rgb(255, 255, 255);
    border: none;
    min-width: 306px;
}

.matching-jobs-card {
    background-color: white;
    border-radius: 12px;
    min-width: 498px;
    font-weight: 600;
}

.btn-view-details {
    padding: 12.663px 35.755px;
    color: #492A99 !important;
    font-size: 16.388px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.656px;
    border: 1px solid #492A99 !important;
}

.btn-whatsapp-btn-cst {
    color: #36BB22 !important;
    font-size: 24px;
    font-weight: 500;
    border-radius: 1px solid #36BB22;
    border-color: #36BB22 !important;
    border-radius: 10px;
}

.cst-relevant-years-of-experience input[type=number] {
    border-radius: 12px !important;
    border: 1px solid #492a99;
    background: #fff;
    height: 48px;
    font-weight: 500;
    border: 1px solid #492a99
}

.cst-cursor {
    cursor: pointer;
}

.display-cst-requirements {
    gap: 12px;
}

.cst-width {
    width: 100%;
    border: 1px solid #000;
    padding-left: 16px;
    padding-right: 16px;
    color: #000;
    font-weight: 500;
}

.cls-for-full-width-cal .react-datepicker-wrapper {
    width: 100%;
}

.cst-position-for-admin-hire {
    gap: 72px;
}

.custom-display-manage-mbile-desk {
    display: flex;
    gap: 38px;
}

.add-collaborator-modal-cst {
    max-width: 44%;
    max-height: 44%;
}

.example-emp-help-modal-cst {
    max-width: 50%;
}

.rise-tickit-modal-cst {
    max-width: 44%;
    max-height: 40%;
}

.cst-input-field-made {
    border-radius: 12px !important;
    border: 1px solid #492a99;
    background: #fff;
    height: 60px;
    font-weight: 500;
    padding: .5rem 1rem;
}

.cst-input-field-made span {
    color: #BCBCBC;
    font-size: 18px;
    font-family: Work Sans;
    font-weight: 500;
}

.attach-kit-cst li {
    font-weight: 500;
}

.cst-all-jobs-sections {
    gap: 2.5%;
    align-items: center;
}

.ApplyForJobModal-cst label {
    color: #BCBCBC;
}

/* Hide the scrollbar in WebKit browsers (Chrome, Safari) */
.d-flex::-webkit-scrollbar {
    width: 0.5em;
}

.table-responsive::-webkit-scrollbar-thumb {
    background-color: transparent;
}

/* remove scollbar for tables  */

.table-responsive::-webkit-scrollbar {
    width: 0.5em;
}

.d-flex::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.cst-scollbar-add {
    gap: 16px;
    overflow-x: auto;
}

.add-kit-button {
    min-width: 50%
}

.delete-button {
    min-width: 50%
}

.cst-card-img {
    width: 79.93px;
    height: 73px;
    /* top: 325px;
    left: 408.9px; */
    border-radius: 9px;

}

.cst-image-interview-side-model {
    width: 98px;
    height: 98px;
    border-radius: 17px;
    border: 1px solid #000;
}

.expirence-image-card-cst {
    min-width: 56.443px;
    min-height: 52px;
    max-width: 56.443px;
    max-height: 52px;
    border-radius: 50%;
}

.employye-image-cst-table {
    border-radius: 50%;
    width: 48px;
    height: 48px;
}

.congratulations-paragraph {
    color: #BCBCBC;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
}

.finantial-information h2 {
    font-weight: 700;
}

/* .help-table-admin-cst th {
    min-width: 100%
} */

.perks-employee h4 {
    font-weight: 600;
}

.invoice-no {
    font-weight: 600;
}

.cst-font-weight-600 {
    font-weight: 600;
}

.invoice-cst-owow h2 {
    font-weight: 600;
}

.billing-invoice-details th {
    font-weight: 600;
}


.billing-invoice-details td {
    font-weight: 600;
}

.billing-invoice-details tr {
    font-weight: 600;
}

.cst-min-height-input-employee input[type=number] {
    height: 48px;
}

.cst-pdf-upload-resume {
    height: 60px;
    padding-top: 16px;
}

.custom-datepicker {
    padding: 16px;
    border-radius: 12px !important;
    border: 1px solid #492a99;
    background: #fff;
    height: 60px;
    color: #010101;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -.8px
}

:where(.css-dkbvqv).ant-picker .ant-picker-input>input:focus,
:where(.css-dkbvqv).ant-picker .ant-picker-input>input:focus-within {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

:where(.css-dev-only-do-not-override-dkbvqv).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-dkbvqv).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-dkbvqv).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #492a99;
}

:where(.css-dev-only-do-not-override-dkbvqv).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-dkbvqv).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-dkbvqv).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner:hover {
    background-color: #492a99;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    color: #fff;
    background: #492a99 !important;
}

:where(.css-dev-only-do-not-override-dkbvqv).ant-picker .ant-picker-input>input {
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0px;
}

:where(.css-dev-only-do-not-override-dkbvqv).ant-picker-dropdown .ant-picker-header-view button:hover {
    color: #492a99;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-dropdown .ant-picker-header-view button:hover {
    color: #492a99;
}

.change-password-cancle-btn {
    min-width: 326px;
}

.change-password-submit-btn {
    min-width: 326px;
}

.launguage-proficiency-cst {
    gap: 16px;
}

.image-for-profile-cst {
    border-radius: 50%;
    border: 1px solid;
    width: 130px;
    height: 130px;
}

.spoc-image-cst-profile {
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.employee-image-card-cst {
    border: 1px solid;
    border-radius: 50%;
    height: 130px;
    width: 130px;
}



.font-size-small-div h4 {
    font-size: 20px;
}

.cst-jobs-gap-cls {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
}

.ApplyForJobModal-cst h3 {
    font-weight: 700;
}

.credentialUrl-cst {
    color: #492A99 !important;
    font-weight: 600;
    text-decoration: underline !important;
}

.cst-job-listing h4 {
    font-size: 18px;
}

.cst-job-listing input[type=checkbox] {
    font-size: 16px;
}

.cst-position-for-admin-hire h3 {
    font-weight: 600;
}

.sarchByRolesandSkills-cst {
    height: 50px !important;
    font-size: 18px !important;
    color: #000 !important;
    font-weight: 500 !important;
    border-radius: 12px !important;
    border: 1px solid #000 !important;
    padding: 16px 40px 16px 16px;
    margin: 5px 10px;
    /* background-image: url('./Assets/Icons/SearchIcon.png') !important;
    background-size: 18px !important;
    background-position: right 16px center !important;
    background-repeat: no-repeat !important; */
}

.clear-filter {
    color: white;
    font-weight: 500;
    word-wrap: break-word;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #EB001B;
    border-radius: 16px;
    border: none;
}

Ḥ .apply-filter {
    color: white;
    font-weight: 500;
    word-wrap: break-word;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #492A99;
    border-radius: 16px;
    border: none;
}

.filter-two-buttons {
    margin-top: 12px;
}

.cst-react-quill p {
    color: #000;
    font-size: 16px;
    letter-spacing: 0px
}

.ql-toolbar {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.ql-container {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.ql-editor.ql-blank::before {
    font-style: normal !important;
    font-family: 'Work Sans', sans-serif;
}

.black-para p {
    color: #000;
}

.lable-heading {
    font-weight: 700;
    font-size: 16px;
}

.cst-h6-heading-css-main h6 {
    font-weight: 600;
}

.cst-h6-heading-css-main label {
    font-weight: 500;
}

.outsource-interview-cst {
    padding: 16px 22px;
    background: #492a99 !important;

    border-radius: 16px;
    color: #fff !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -.88px;
}

.request-for-interview-card-sidbar h5 {
    font-weight: 600;
}

.job-requirementcard-cst {
    background-color: #F0EDFD;
    border-radius: 8px;
    border: none;
}

.request-outsource-interview-cst {
    border-radius: 16px;
    padding: 10px 48px 10px 48px;
    background-color: #492A99 !important;
    color: #fff !important;
    width: 100%;
}

.second-recruitment-btn {
    font-size: 12px;
}

.request-recived-card-cst {
    background-color: #492A99;
    color: #FFF;
    border-radius: 28px;
    padding: 36px;
    width: 80%;
}

.request-recived-card-cst h2 {
    font-weight: 700;
}

.request-recived-card-cst h4 {
    font-weight: 400;
}

.outsourced-interview-result-btn img {
    width: 30px;
}

.outsourced-interview-result-btn {
    display: flex;
    justify-content: flex-start;
    padding: 8px;
    gap: 6px;
    border-radius: 36px;
    border: 1px solid #492a99 !important;
    background: #fff;
    line-height: normal;
    letter-spacing: -.48px;
    font-size: 12px;
    width: 100%;
    align-items: center;
    margin-bottom: 8px;
    font-weight: 500;
}

/* CSS */
.custom-row {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-row-selected {
    background-color: #F3EEFF;
    border-left: 12px solid #492A99;
}

.custom-row h5 {
    font-weight: 700;
}

.cst-static-buttons-sidemodel {
    position: sticky;
    bottom: 0px;
    /* background: white;
    box-shadow: -5px 0px 14px rgba(0, 0, 0, 0.05); */
}

.cst-static-buttons-sidemodel-view-profile {
    position: fixed;
    bottom: 0px;
    /* background: white;
    box-shadow: -5px 0px 14px rgba(0, 0, 0, 0.05); */
}

/* .matching-jobs-card h5{
    word-break: break-all;
} */

.delete-modal label {
    font-weight: 500;
}

.resend-email-foremployer {
    border-radius: 16px;
    color: #492a99 !important;
    border: 1px solid #492a99 !important;
}

.outsdesc {
    max-height: 30vh !important;
    overflow-y: scroll;
    border-radius: 20px;
    border: 1px solid #dadada;
    background: white;
    padding: 16px;
}

.delete-job-post-sidemodel-view-profile {
    position: sticky;
    bottom: 0px;
    float: right;

}

.add-talent-card-img {
    height: 74px;
    width: 74px;
    border-radius: 8px;
}

.card-cst-bg-profile {
    background-color: #492A99;
    border-radius: 24px;
    padding: 10px
}

/*  */
.ui-widgets {
    position: relative;
    width: 8rem;
    height: 8rem;
    border-radius: 9rem;
    border: 16px solid #AB8BFC;
    border-left-color: AB8BFC;
    border-top-color: white;
    border-bottom-color: AB8BFC;
    border-right-color: white;
    text-align: center;
    box-sizing: border-box;
}

.ui-values {
    top: 36px;
    position: absolute;
    left: 10px;
    right: 0;
    font-weight: 700;
    color: white;
}

.btn-complete-it-now {
    color: #492A99 !important;
    font-weight: 500;
    word-wrap: break-word;
    background-color: white !important;
    padding: 12px;
    border-radius: 16px;
}

.list-unstyled li {
    margin-bottom: 4px;
}

.outsdesc p {
    font-size: 18px;
}

.cst-heading-changes h3 {
    font-size: 22px;

}

.hire-talents-card h4 {
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
}

.cst-padding-admin-hire-talets {
    padding-top: 30px;
    padding-bottom: 40px;
    padding-left: 28px;
    padding-right: 28px;
}

.date-picker-input input {
    background: #fff;
    font-weight: 500;
    font-size: 17px !important;
    font-family: Work Sans !important;

}

.ant-picker-panel-layout button {
    font-family: Work Sans !important;
}

.ant-picker-panel-layout div {
    font-family: Work Sans !important;
}

.cst-wrapper-100 .react-datepicker-wrapper {
    width: 100%;
}

.cst-image-employee-portal {
    width: 80px;
    height: 80px;
    cursor: pointer;
    border-radius: 50%;
}

.cst-margin-top-for-red-limit-exceed {
    margin-top: 16px;
    margin-left: 16px;
}

.display-center-cst-hiretalent-profile {
    align-items: center;
}

.details-card-for-input label {
    color: rgba(0, 0, 0, 0.55);
    font-weight: 600;
}

.cst-clear-apply-btn {
    flex-direction: row;
    justify-content: space-between;
}

.my-account-cst-display img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

/* .my-account-cst-display h4 {
    font-size: 18px;
} */

.perks-font-kit-cst h4 {
    font-weight: 600;
}

.perks-font-kit-cst h5 {
    font-weight: 500;
}

.perks-font-kit-cst ul {
    font-weight: 500;
}

.FieldPlaceholderWrap {
    align-items: center;
    background: #fff;
    border: 1px solid #492a99;
    border-radius: 12px !important;
    display: flex;
    gap: 5px;
    padding-right: 10px;
    font-weight: 500;
    font-size: 18px !important;
}

.FieldPlaceholderWrap span {
    color: #6b6b6b99;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.FieldPlaceholderWrap .form-control {
    background: #fff;
    border: 0 solid #cecccc;
    height: 60px;
}

.FieldPlaceholderWrap .form-control:focus {
    box-shadow: none;
}

.card-cst-GPT-vetting {
    border-radius: 16px;
    background: rgb(255, 255, 255);
    border: none;
    min-width: 306px;
}

.start-test-button {
    color: white !important;
    font-weight: 500;
    word-wrap: break-word;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #492A99 !important;
    border-radius: 16px;
    border: none;
}

.list-of-numbers-text ul {
    list-style: decimal;

}

.list-of-numbers-text li {
    font-size: 18px;
    font-weight: 500;
}

.cst-processing-steps-card {
    background-color: #F1EBFF;
    border-radius: 16px;
    border: none;
}

.cst-Lets-get-started {
    border-radius: 16px;
    border: none;
    background: rgb(255, 255, 255);

}

.question {
    margin-bottom: 20px;
}

.code-snippet {
    background-color: #000;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto;
    /* Add scrollbar for long code */
}

.code-snippet pre {
    margin: 0;
}

/* ul {
    list-style-type: none;
    padding: 0;
}

ul li {
    margin-bottom: 5px;
} */
.matching-jobs-card h5 {
    font-size: 18px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.black-header-part-logoand-text {
    background-color: #130A2D;
}

.black-header-part-logoand-text label {
    color: #CBC9D1;
    font-weight: 500;
}

.black-header-part-logoand-text h5 {
    color: white;
    font-weight: 600;
}

.cst-background-white-div {
    background-color: white;
}

.cst-background-white-div h5 {
    color: #130A2D;
    font-family: Work Sans;
    font-weight: 600;
    line-height: 25.02px;
    word-wrap: break-word
}

.cst-background-white-div label {
    color: #3D3D3D;

}

.employee-my-profile h4 {
    font-size: 20px;
}

.checkbox-text a {
    text-decoration: underline;
}

.card-cst-GPT-vetting img {
    width: 50px;
}

.btn-whatsapp-btn-cst img {
    width: 30px;
}

.cst-dropdown-hover:hover {
    background-color: rgba(188, 188, 188, 1);
}

.cst-dropdown-hover {
    padding: 12px;
    position: relative;
}

.cst-dropdown-hover::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
}

.cst-dropdown-hover:hover::after {
    background-color: rgba(188, 188, 188, 1);
}

.cst-width-for-taba-and-all {
    width: 50%;
}

.tooltip-next-button {
    background-color: #fff;
    color: #492A99;
    font-weight: 500;
    border-radius: 12px;
    border: 1px solid #492A99;
}

.offcanvas-cst-width {
    width: 50% !important;
}

.continue-with-google {
    border: 1px solid #492A99 !important;
    border-radius: 16px;
    background-color: #fff !important;
    color: #1D1C1B !important;
    font-weight: 500;
    word-wrap: break-word;
    width: 100%;
    padding: 14px;
}

.mobile-div-notification h5 {
    font-size: 16px;
    margin-bottom: 12px;
}

.mobile-div-notification h6 {
    font-size: 14px;
}

.mobile-div-notification img {
    width: 42px;
    height: 42px;
    flex-wrap: wrap;
    margin-bottom: 12px;

}

.initials-avatar {
    width: 66px !important;
    height: 66px !important;
    background-color: #492A99 !important;
}

.driver-popover {
    background-color: #492A99 !important;
    color: #fff !important;
    border-radius: 16px !important;
    font-family: 'Work Sans', sans-serif !important;
}

.driver-popover-title {
    font-family: 'Work Sans', sans-serif !important;
}

.driver-popover-description {
    font-family: 'Work Sans', sans-serif !important;
}

.driver-popover-footer button {
    font-family: 'Work Sans', sans-serif !important;
}

.driver-popover-progress-text {
    font-family: 'Work Sans', sans-serif !important;
}

.driver-popover-close-btn:hover,
.driver-popover-close-btn:focus {
    color: #fff !important;
}

.driver-popover-progress-text {
    color: #fff !important;
}

.contract-card-cst {
    padding: 22px;
    border-radius: 16px;
}

.contract-card-cst p {
    font-size: 16px;
    color: #000;
}

.end-contract-btn {
    border-color: red !important;
    border-radius: 16px !important;
    background: #fff !important;
    color: red !important;
    padding: 16px;
}


.contract-ended-cst-background h2 {
    font-weight: 700;
    color: #fff
}

.contract-ended-cst-background p {
    color: #fff
}

/* New Employee Assesment Vetting */

.card-header-cst {
    background-color: #E8E5EE;
    border: none;
}

.card-cst-new-dsh {
    border: none;
    border-radius: 4px;
    border-color: white;
    border: 2px solid white;
}

.main-ashonrd-test {
    background-color: #FBF9FF;
}

.card-cst-dashbord {
    border-radius: 8px;
    background: rgb(255, 255, 255);
    border: none;
}

.bg-200 {
    background-color: rgb(237 242 249);
}

.card-header-cst strong {
    color: #492A99;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif
}

.card-header-cst label {
    color: #492A99;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif
}

.card-header-cst .form-check-input[type=checkbox] {
    border-radius: 16px !important;
}

.chat-content-scroll-area {
    height: calc(100vh - 16em);
    overflow-y: scroll;
    overflow-x: hidden;
}

.chat-message-left {
    max-width: calc(100% - 6rem);
    margin-bottom: 0.25rem;
    display: inline-block;
    position: relative;
    background-color: rgba(232, 229, 238, 1);
    border-radius: 32px 32px 32px 0px;
}

.chat-message-right {
    max-width: calc(100% - 6rem);
    margin-bottom: 0.25rem;
    display: inline-block;
    position: relative;
    background-color: rgba(73, 42, 153, 1);
    border-radius: 32px 32px 0px 32px;
    margin-right: 8px;
}

.chat-message-left div {
    font-weight: 400;
    color: rgba(26, 26, 27, 1);
}

.chat-message-left h6 {
    font-weight: 600;
}

.chat-message-right div {
    color: rgba(248, 249, 253, 1);
    font-weight: 400;
}

.chat-message-right h6 {
    color: rgba(248, 249, 253, 1);
    font-weight: 600;
}

.scroller::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
    height: 8px;
    /* Height of the scrollbar (for horizontal scroll) */
}

.scroller::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Radius of the scrollbar thumb */
}

/* .scroller::-webkit-scrollbar-thumb:hover {
    background-color: #D9D9D9;
} */
.scroller::-webkit-scrollbar-track {
    background-color: #ffff;
    border-radius: 10px;
}

.dyna-pro-bar-cst {
    border-radius: 41px;
    height: 6px;
    background: #492A99;
}

.contract-ended-cst-background h2 {
    font-weight: 700;
    color: #fff
}

.contract-ended-cst-background p {
    color: #fff
}

.feeback-div {
    border: 1px solid #C6C6C6;
    border-radius: 12px;
    padding: 6px
}

.accordion {
    background-color: #E8E5EE !important;
    border-radius: 6px;
}

.card-header-cst {
    background-color: #E8E5EE;
    border-bottom: none;
}

.scrollable-content {
    max-height: 400px;
    /* Adjust the height as needed */
    overflow-y: auto;
}

.scrollable-content {
    max-height: 400px;
    /* Adjust the height as needed */
    overflow-y: auto;
    /* scrollbar-width:thin;   */
    /* scrollbar-color: #888 #F1F1F1; For Firefox */
}

/* Webkit browsers (Chrome, Safari) */
.scrollable-content::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
}

.scrollable-content::-webkit-scrollbar-track {
    background: #F1F1F1;
    /* Background color of the track */
    border-radius: 16px;
}

.scrollable-content::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    /* Color of the scrollbar thumb */
    border-radius: 16px;
    border: 2px solid #F1F1F1;
    /* Creates padding around the thumb */
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
    background-color: #D9D9D9;
    /* Color of the scrollbar thumb on hover */
}

@media (max-width:1600px) {

    .fix-margin-for-or,
    .margin-fix-bottam {
        margin-bottom: 16px
    }

    .left-clumn-heading {
        font-size: 46px
    }

    .view-details-btn {
        font-size: 16px !important;
    }

    .btn,
    .privacy-policy {
        font-size: 18px
    }

    .welcome-heading {
        font-size: 32px;
        margin-top: 12px
    }

    .owow {
        font-size: 58px;
        margin-top: 32px
    }

    input[type=date],
    input[type=email],
    input[type=password],
    input[type=tel],
    input[type=text],
    input[type=number] {
        /* height: 60px; */
        font-size: 18px;
    }

    .fix-margin-for-or {
        margin-top: 26px
    }

    .create-account-btn {
        padding: 14px
    }

    /* .social-media-btn { */
    /* padding: 6px */
    /* } */

    .heado-para {
        font-size: 18px
    }

    .nsm7Bb-HzV7m-LgbsSe {
        font-size: 18px !important
    }

    /* .dahbord-cst-card img {
        width: 50px;
    } */

    /* .dahbord-cst-card h2 {
        font-size: 24px;
    } */

    .dahbord-cst-card h4 {
        font-size: 22px;
    }

    .dahbord-cst-card {
        min-width: 240px;
    }

    /* .button-active {
        padding: 8px 20px;
     } */

    .payment-pending-btn {
        min-width: 190px;
    }

    label {
        font-size: 16px;
    }

    .general-company-details-cst h4 {
        font-size: 20px;
    }

    .finantial-information h4 {
        font-size: 20px;
    }

    .finantial-information h2 {
        font-size: 28px;
        font-weight: 700;
    }

    .general-company-details-cst h2 {
        font-size: 28px;
    }

    h1 {
        font-size: 36px;
    }

    /* .matching-jobs-card h5 {
        font-size: 16px;
    } */

    .cst-all-jobs-sections h2 {
        font-size: 28px;
    }

    .cst-all-jobs-sections h3 {
        font-size: 20px;
    }

    .cst-all-jobs-sections h4 {
        font-size: 18px;
    }

    .employee-white-card h2 {
        font-size: 28px;
    }

    .employee-white-card h3 {
        font-size: 18px;
    }

    .employee-white-card h4 {
        font-size: 18px;
    }

    .cst-all-jobs-description h3 {
        font-size: 20px;
    }

    .cst-all-jobs-description h4 {
        font-size: 18px;
    }

    .cst-all-jobs-description p {
        font-size: 20px;
    }

    .verify {
        font-size: 18px;
    }
}

@media (max-width:1399.98px) {

    input[type=date],
    input[type=email],
    input[type=password],
    input[type=tel],
    input[type=text],
    input[type=number] {
        font-size: 16px !important;
    }

    td {
        font-size: 18px;
    }

    .phno {
        font-size: 16px;
        width: 96px;
    }

    .custom-datepicker {
        font-size: 16px;
        padding: 20px 12px 0px 16px;
    }

    :where(.css-dev-only-do-not-override-dkbvqv).ant-picker .ant-picker-input>input {
        font-size: 17px;
        padding: 0px;
    }

    .congratulations-owow {
        margin-bottom: 70px
    }

    .social-media-btn {
        padding: 14px;
    }

    /* .marshmello-image-box {
        margin-top: 18%
    } */

    .mail-box {
        margin-bottom: 40px;
        margin-top: 0
    }

    .verify {
        left: 82%;
        top: -40px
    }

    .message-inputs input {
        width: 50px;
        height: 50px
    }


    a,
    input[type=checkbox] {
        font-size: 16px
    }

    .social-media-btn {
        font-size: 18px
    }

    .log-in,
    p {
        font-size: 18px
    }

    .left-clumn-heading {
        font-size: 42px
    }

    .left-clumn-first-heading {
        margin-top: 30%
    }

    .verified-button {
        border: 2px solid #fff;
        padding: 8px 20px;
        font-size: 16px
    }

    .welcome-heading {
        /* font-size: 46px; */
        margin-top: 46px;
    }

    .owow {
        margin-top: 10px;
        font-size: 60px
    }

    .right-side-container {
        margin-left: 54px
    }

    .hero-left-col {
        background-image: url("Assets/Images/heroImage.png");
        background-size: cover;
        padding-top: 100px;
        position: sticky;
        height: 100vh;
        min-height: 100%;
        background-position: center
    }

    .personal-details-heading-right-side {
        margin-top: 80px
    }

    .mobile-two-buttons {
        margin-bottom: 10%
    }

    .job-posted-card-cst {
        min-width: 422px;
        min-height: 163px;
    }

    /* .overview-sec-label {
        font-size: 16px;
    }
    
    h4 {
        font-size: 18px;
    } */
    .dahbord-cst-card img {
        width: 50px;
    }

    .dahbord-cst-card h2 {
        font-size: 20px;
    }

    .dahbord-cst-card h4 {
        font-size: 16px;
    }

    .dahbord-cst-card {
        min-width: 206px;
    }

    .image-for-profile-cst {
        width: 130px;
        height: 130px;
    }

    .cst-all-jobs-sections {
        gap: 26px;
        justify-content: flex-start;
        align-items: center;
    }

    .cst-all-jobs-sections h2 {
        font-size: 28px;
    }

    .cst-all-jobs-sections h3 {
        font-size: 24px;
    }

    .cst-all-jobs-sections h4 {
        font-size: 22px;
    }

    .hire-talents-card {
        min-width: 296px;
    }

    .hire-talents-card h4 {
        font-size: 18px;
    }

    .hire-talents-card p {
        font-size: 12px;
    }

    .hire-talents-card h5 {
        font-size: 13px;
    }

    .hire-talents-card h6 {
        font-size: 12px;
    }

    .button-active {
        border-radius: 15px;
        padding: 6px 16px;
        font-size: 14px;
    }

    .profile-heading-cst {
        font-size: 16px;
    }

    .add-kit-button {
        min-width: 66%
    }

    .delete-button {
        min-width: 66%
    }

    .change-password {
        font-size: 20px;
    }

    .cost-c-td h2 {
        font-size: 20px;
    }

    .btn-savings {
        font-size: 13px;
    }

    .navtab_wrap .nav-tabs .nav-link {
        font-size: 14px;
    }

    .btn-indirect-cost {
        font-size: 14px;
    }

    .btn-salary {
        font-size: 14px;
    }

    .cost-c-th h4 {
        font-size: 22px;
    }

    label {
        font-size: 14px;
    }

    .general-company-details-cst h4 {
        font-size: 16px;
    }

    .general-company-details-cst h2 {
        font-size: 24px;
    }

    .developer-information-card {
        max-width: 294px;
    }

    .hire-now-btn {
        min-width: 153.38px;
        font-size: 12px !important;
    }

    .view-profile-btn {
        min-width: 96px;
        font-size: 12px !important;
    }

    .rejected-button {
        min-width: 266px;
        font-size: 14px;
    }

    .interview-schdule-button {
        font-size: 14px !important;
        min-width: 266px;
    }

    .cst-image-interview-side-model {
        min-width: 98px;
        min-height: 98px;
        max-width: 98px;
        max-height: 98px;
        border-radius: 17px;
        border: 1px solid #000;
    }

    .request-for-interview-card-sidbar h2 {
        font-size: 18px;
    }

    .request-for-interview-card-sidbar h4 {
        font-size: 16px;
    }

    .button-pending-payment {
        min-width: 190px;
    }

    .button-active {
        padding: 8px 20px;
        /* min-width: 190px; */
    }

    .cst-manage-collaborator-tbl th {
        min-width: 0px;
    }

    .checkbox-text {
        font-size: 16px;
    }

    .congratulations-heading {
        font-size: 40px;
    }

    .congratulations-paragraph {
        font-size: 26px;
    }

    .change-password-modal-cst {
        max-width: 40%;
    }


    .invoice-cst-owow h2 {
        font-size: 30px;
    }

    h1 {
        font-size: 32px;
    }

    .manully-pay-btn,
    .pay-now-btn {
        font-size: 14px;
    }

    .personal-details-heading-right-side {
        font-size: 36px;
    }


    .add-skills-modal-cst {
        max-width: 50%;
    }

    .add-expirence-modal-cst {
        max-width: 50%;
    }

    .add-certifications-modal-cst {
        max-width: 50%;
    }

    .add-education-modal-cst {
        max-width: 50%;
    }

    .cst-terms-con-accept {
        font-size: 20px !important;
    }

    .ApplyForJobModal-cst {
        max-width: 50%;
    }

    .verify {
        font-size: 18px
    }

    .change-password-cancle-btn {
        min-width: 240px;
    }

    .change-password-submit-btn {
        min-width: 240px;
    }

    .profile-modal-toggle-cst {
        max-width: 50%;
    }

    .employee-image-card-cst {
        border-radius: 50%;
        border: 1px solid;
        width: 112px;
        height: 112px;
    }

    .profile-modal-toggle-cst {
        max-width: 50%;
    }

    .cst-all-jobs-description p {
        font-size: 16px;
    }

    .outsource-interview-cst {
        padding: 12px 14px;

    }

    .btn-for-reject {
        padding: 12px 14px;
    }

    .custom-display-manage-mbile-desk {
        gap: 10px;
    }

    .request-recived-card-cst {

        padding: 16px;
    }

    .personal-details-heading {
        font-size: 38px;
    }

    .owow-lets-started {
        padding-top: 84px;
    }
}

@media (max-width:1199.98px) {
    .welcome-heading {
        /* font-size: 42px; */
        margin-top: 54px
    }

    .heado-para {
        font-size: 22px
    }

    .input-group-lg svg {
        cursor: pointer;
        position: relative;
        left: 86%;
        top: -40px;
        font-size: 22px
    }

    .left-clumn-first-heading {
        margin-top: 80%
    }

    .owow {
        margin-top: 36%;
        font-size: 70px
    }

    /* #sidebar {
        width: 0px;
    } */

    #content {
        margin-left: 0px;
    }

    .my-account-tabs-list a {
        font-size: 22px;
    }


    #sidebar.active {
        margin-left: 0px;
        background: white;
        width: 300px
    }

    #sidebar {
        width: 0px
    }

    .cst-width-for-taba-and-all {
        width: 80%;
    }

    .cst-hide-fot-tab {
        display: none;
    }

    .card-cst-GPT-vetting h5 {
        font-size: 18px;
    }

    /* 
    .desktop-logo {
        display: none
    } */
    .offcanvas-cst-width {
        width: 100% !important;
    }

    .card-cst-bg-profile h4 {
        text-align: center;
    }

    .card-cst-bg-profile h5 {
        text-align: center;
    }
}

@media (max-width:767.98px) {
    #sidebar {
        position: inherit;
        margin-left: -414px;
        width: 100%;
        /* min-width: 100% */
    }

    #content {
        margin-left: 40px
    }

    #sidebar.active {
        margin-left: 0;
        background-color: transparent;
        min-width: 100%
    }

    #sidebarCollapse span {
        display: none
    }

    .cst-profile-info-header {
        display: flex;
        justify-content: center;
        flex-direction: column
    }

    .cst-account-card {
        padding: 16px
    }

    .cst-subnavbar-for-dashbord {
        display: flex;
        gap: 2px;
        flex-direction: column
    }

    .button-container {
        padding: 6px;
        gap: 8px;
        border-radius: 16px
    }

    .change-password,
    .change-your-login {
        font-size: 12px
    }

    .cst-save-das {
        min-width: 130px
    }

    .left-clumn-heading {
        font-size: 23px
    }

    .cst-button-alignment {
        flex-direction: column
    }

    .input-group-lg svg {
        left: 88%
    }

    .verify {
        left: 80%
    }

    .offcanvas-cst-width {
        width: 100% !important;
    }

    .card-cst-bg-profile h4 {
        text-align: center;
    }

    .card-cst-bg-profile h5 {
        text-align: center;
    }

    .cst-hide-fot-tab {
        display: block;
    }
}

@media (max-width:575.98px) {

    .cst-container-bg,
    .new-right-side-col {
        background: linear-gradient(180deg, #492a99 0, #000 100%)
    }

    .heado-para,
    .lets-started-heading,
    .mail-box,
    .mobile-para-msg,
    .owow,
    .welcome-heading {
        text-align: center
    }

    .desktop-logo {
        display: none
    }

    .mobile-logo {
        display: block
    }

    .input-group-lg svg {
        left: 88%
    }

    .mobile-two-buttons {
        flex-direction: column
    }

    .marshmello-image-box {
        margin-top: 0;
        margin-bottom: 20px
    }

    .congratulations-owow {
        margin-bottom: 162px;
        color: #000;
        font-family: Pacifico, cursive;
        font-size: 70px;
        font-style: normal;
        font-weight: 400;
        line-height: 63px
    }

    .congratulations-heading {
        color: #fff;
        text-align: center;
        font-family: Pacifico;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: .72px
    }

    .congratulations-paragraph {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        font-family: 'Work Sans', sans-serif
    }

    .new-right-side-col {
        height: 100vh
    }

    .lets-started-heading {
        color: #fff
    }

    .mail-box {
        margin-top: 10px
    }

    .verify {
        left: 70%
    }

    .right-side-container {
        margin-left: 36px;
        margin-right: 36px
    }

    .welcome-heading {
        padding-top: 16px;
        padding-bottom: 16px;
        color: #000;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -.64px
    }

    .heado-para,
    .mobile-para-msg,
    .privacy-policy {
        font-style: normal;
        font-weight: 500;
        letter-spacing: -.56px
    }

    .cst-button-alignment {
        display: flex;
        flex-direction: column;
        gap: 10px
    }

    .create-account-btn {
        padding: 14px
    }

    .social-media-btn {
        padding: 16px
    }

    .already-account {
        align-items: center
    }

    .cst-container-bg {
        height: 100%;
        min-height: 100vh
    }

    .cst-mobile-bg {
        border-radius: 60px 60px 0 0;
        background: #fff;
        box-shadow: 0 -9px 54px 0 rgba(0, 0, 0, .25);
        height: 100%;
    }

    .owow {
        color: #fff;
        margin-top: 0
    }

    .margin-fix-bottam {
        margin-bottom: 16px
    }

    input[type=checkbox] {
        font-size: 20px
    }

    .heado-para {
        font-size: 14px;
        line-height: 19px
    }

    .privacy-policy {
        font-size: 14px;
        line-height: normal
    }

    .email-sent-text {
        font-size: 36px
    }

    .mobile-para-msg {
        font-size: 14px;
        line-height: 21px
    }

    .right-side-container-personal {
        margin: 22px
    }

    .owow-savings {
        width: 100%
    }

    .progress-lines {
        gap: 16px;
        /* flex-direction: column */
    }

    .btn-time-am,
    .btn-time-pm {
        height: 52px;
        min-width: 50px;
        min-height: 40px
    }

    .first-dot-img {
        margin-top: 24px
    }

    .second-dot-img {
        margin-top: 52px
    }

    .third-dot-img {
        margin-top: 54px
    }

    .mobile-flex-col-cst {
        flex-direction: column;
        flex-wrap: wrap
    }

    .time-input {
        width: 50px;
        height: 40px;
        padding-left: 12px
    }

    .formobile-all-jobs {
        flex-direction: column;
        flex-wrap: wrap;
        gap: 26px;
        align-items: start;
    }

    .my-account-tabs-list a {
        font-size: 16px;
    }

    .top-navigation p {
        color: #BCBCBC;
        font-size: 19px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.76px;
    }

    .hire-talents-card {
        min-width: 320px;
        min-height: 163px;
        border-radius: 23px;
        border: 1px solid #dadada;
        background: #fff;
    }

    .matching-jobs-card {
        background-color: white;
        border-radius: 12px;
        min-width: 320px;
    }

    .job-posted-card-cst {
        min-width: 320px;
        min-height: 163px;
        border-radius: 23px;
        border: 1px solid #dadada;
        background: #fff;
    }

    .display-cst-requirements {
        gap: 0px;
    }

    .cst-position-for-admin-hire {
        gap: 16px;
        flex-direction: column;
    }

    .css-1ud1chn-control {
        flex-wrap: unset !important;
        height: 52px;
    }

    .css-1up50s6-control {
        flex-wrap: unset !important;
        height: 52px;
    }

    .css-1q7xutl-control {
        flex-wrap: unset !important;
        height: 52px;
    }

    .css-1reto9o-control {
        min-height: 52px;
        max-height: 52px;
        flex-wrap: unset !important;
    }

    .css-1q7xutl-control:hover {
        flex-wrap: unset !important;
        height: 52px;
    }

    .nsm7Bb-HzV7m-LgbsSe {
        border: none !important;
        font-size: 22px !important;
        background-color: #fff !important
    }

    .custom-display-manage-mbile-desk {
        display: inline;
    }

    .cst-scollbar-add {
        gap: 0px;
        flex-direction: column;
        overflow-x: hidden;
    }

    .general-company-details-cst h2 {
        font-size: 20px;
    }

    h1 {
        font-size: 30px;
    }

    .change-password-modal-cst {
        max-width: 100%;
    }

    .help-table-admin-cst th:not(:last-child) {
        min-width: 160px;
    }

    .chnagepasss-cst-col {
        flex-direction: column-reverse;
    }

    .add-skills-modal-cst {
        max-width: 100%;
    }

    .rise-tickit-modal-cst {
        max-width: 100%;
    }

    .add-expirence-modal-cst {
        max-width: 100%;
    }

    .add-certifications-modal-cst {
        max-width: 100%;
    }

    .add-education-modal-cst {
        max-width: 100%;
    }

    /* #add-collaborator {
        display: none;
    } */

    .ApplyForJobModal-cst {
        max-width: 100%;
    }

    .example-emp-help-modal-cst {
        max-width: 100%;
    }

    .chnagepasss-cst-col-btn {
        flex-direction: column;
    }

    .launguage-proficiency-cst {
        gap: 6px;
    }

    .profile-modal-toggle-cst {
        max-width: 100%;
    }

    .image-for-profile-cst {
        width: 78px;
        height: 78px;
    }

    .cst-jobs-gap-cls {
        gap: 12px;
        flex-direction: column;
        align-items: start;
    }

    .btn-for-reject {
        width: 76%;
    }

    .request-recived-card-cst {
        padding: 14px;
        width: 100%;
    }

    .outsourced-interview-result-btn img {
        width: 50px;
    }

    .outsourced-interview-result-btn {
        display: flex;
        justify-content: flex-start;
        padding: 10px;
        gap: 6px;
        border-radius: 36px;
        border: 1px solid #492a99 !important;
        background: #fff;
        line-height: normal;
        font-size: 18px;
        align-items: center;
        margin-bottom: 8px;
        font-weight: 500;
    }

    .cst-static-buttons-sidemodel {
        position: relative;
        bottom: 0px;
    }

    .dahbord-cst-card {
        min-width: 160px;
        max-width: 100%;
    }

    .email-color-span {
        color: white;
    }

    /* .create-account-btn-outline {
        border-radius: 16px;
        color: #492a99 !important;
        background-color: #fff !important;
    }

    .create-account-btn {
        margin-top: 12px;
        margin-bottom: 16px;
        border-radius: 16px;
        color: #492a99 !important;
        background-color: #fff !important;
    } */
    .resend-email-foremployer {
        border-radius: 16px;
        color: #492a99 !important;
        background-color: #fff !important;
    }

    .button-active {
        padding: 6px 9px;
    }

    .hire-talents-card h4 {
        font-size: 18px;
    }

    #content {
        /* margin-left: 28px */
        margin-left: auto;
        /* margin-right:auto; */
    }

    .developer-information-card {
        max-width: 300px;
    }

    .cst-padding-admin-hire-talets {
        padding-top: 30px;
        padding-bottom: 22%;
        padding-left: 28px;
        padding-right: 28px;
        height: 100%;
    }

    .cst-image-employee-portal {
        width: 50px;
        height: 50px;
    }

    .my-account-tabs-list {
        position: relative;
        list-style: none;
        padding: 0;
        gap: 26px;
        display: flex;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        border-bottom: 1px solid;
    }

    .phno {
        width: 110px;
    }

    .cst-input-field-made span {
        font-size: 16px;
    }

    .cst-margin-top-for-red-limit-exceed {
        margin-top: 46px;
        margin-left: 16px;
    }

    .btn-add-talent,
    .btn-view-profile {

        padding: 10px 20px;
    }

    .display-center-cst-hiretalent-profile {
        align-items: normal;
    }

    .cst-clear-apply-btn {
        flex-direction: column;
        gap: 12px;
        margin-bottom: 12px;
    }

    .my-account-cst-display img {
        width: 60px;
        height: 60px;
    }

    .my-account-cst-display h1 {
        font-size: 20px;
    }

    .my-account-cst-display h4 {
        font-size: 18px;
    }

    .font-size-small-div h2 {
        font-size: 22px;
    }

    .cst-width-for-taba-and-all {
        width: 300px;
        /* margin: auto; */
        padding: auto !important;
    }
}